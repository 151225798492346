import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import algosdk from 'algosdk';

import { openLoadingModal, closeModal, openConnectModal } from "Root/services/actions/actions";

import { LazyLoadImage } from 'react-lazy-load-image-component';

import Modal from 'react-modal';

import axios from 'axios';

import { base64Decode, base64Encode } from 'Root/helpers/base64';

import toast from 'react-hot-toast';

import ClipLoader from "react-spinners/ClipLoader";

import AlgoImage from 'Root/images/algo.png';

import Banner from 'Root/images/logo.png';
import BannerMobile from 'Root/images/mobile-header.png';

import nftVideoWEBM from 'Root/images/nft.webm';
import nftVideoMP4 from 'Root/images/nft.mp4';
import nftVideoGIF from 'Root/images/nft.gif';
import nftGoraGIF from 'Root/images/carousel/carousel.gif';

import nftholder from 'Root/images/placeholder.jpg';

import NftTraitData from 'Root/files/traits.json';

import Countdown from "react-countdown";

import { useWallet } from '@txnlab/use-wallet-react'


const ShuffleSection = ({ showConnectModal, showLoadingModal, closeModal }) => {
    const [shuffleAssets, setShuffleAssets] = useState([])
    const [shuffleRemaining, setShuffleRemaining] = useState();
    const [whitelistRemaining, setWhitelistRemaining] = useState();
    const [ownWhitelistRemaining, setOwnWhitelistRemaining] = useState();
    const [shuffleDisabled, setShuffleDisabled] = useState(true);
    const [claimedImage, setClaimedImage] = useState('/images/placeholder.jpg');
    const [claimedData, setClaimedData] = useState();
    const [modalIsOpen, setIsOpen] = useState(false);

    const [shuffleAssetsLoading, setShuffleAssetsLoading] = useState(false);

    const [isActive, setActive] = useState("false");

    const shuffleDate = new Date(Date.UTC(2024, 7, 23, 20, 0));

    const { wallets, activeWallet, activeAccount } = useWallet()

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            paddingLeft: 0,
            paddingRight: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '1rem'
        },
    };

    useEffect(() => {
        document.title = "Gorabots Shuffle";
    }, [])

    useEffect(() => {
        if (activeAccount?.address) {
            get_nfts();
        }
        else {
            setShuffleAssets([]);
        }

        get_shuffle_remaining();
        isShuffleTime()
        const shuffleRemainingInterval = setInterval(() => { get_shuffle_remaining(); }, 5000);
        const shuffleTimeInterval = setInterval(() => { isShuffleTime(); }, 200);

        return () => {
            clearInterval(shuffleRemainingInterval);
            clearInterval(shuffleTimeInterval);
        };
    }, [activeAccount])

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (!completed) {
            // Render a countdown
            return <span>{days} {days !== 1 ? "Days" : "Day"} {hours} {hours !== 1 ? "Hours" : "Hour"} {minutes} {minutes !== 1 ? "Minutes" : "Minute"} {seconds} {seconds !== 1 ? "Seconds" : "Second"}</span>;
        }
    };

    function openModal() {
        setIsOpen(true);
    }

    function closePopupModal() {
        setIsOpen(false);
    }


    function handleToggle() {
        setActive(!isActive);
    }

    function isShuffleTime() {
        const now = new Date(new Date(Date.now()).toUTCString());
        if (shuffleDate <= now) {
            setShuffleDisabled(false);
        }
    }

    function get_shuffle_remaining() {
        axios.get(process.env.REACT_APP_API_ADDRESS + "getShuffleRemaining")
            .then((response) => {
                setShuffleRemaining(response.data);
            })
            .catch((e) => {
                setShuffleRemaining(null);
            })

        axios.get(process.env.REACT_APP_API_ADDRESS + "getWhitelistRemaining")
            .then((response) => {
                setWhitelistRemaining(response.data);
            })
            .catch((e) => {
                setWhitelistRemaining(null);
            })

        axios.get(process.env.REACT_APP_API_ADDRESS + `getOwnWhitelistRemaining?address=${activeAccount?.address}`)
            .then((response) => {
                setOwnWhitelistRemaining(response.data);
            })
            .catch((e) => {
                setOwnWhitelistRemaining(null);
            })
    }

    function get_nfts() {
        setShuffleAssetsLoading(true);
        axios.get(process.env.REACT_APP_API_ADDRESS + `getNfts?address=${activeAccount.address}`)
            .then((response) => {
                setShuffleAssets(response.data);
            })
            .catch((e) => {
                setShuffleAssets([]);
            })
            .finally(() => {
                setShuffleAssetsLoading(false);
            })
    }

    async function get_shuffle_transactions() {
        if (!activeAccount.address) {
            await showConnectModal();
        }
        else {
            const shuffle_transactions_response = await axios.get(process.env.REACT_APP_API_ADDRESS + `getShuffleTransactions?address=${activeAccount.address}`).then((res) => res.data)
                .catch((e) => {
                    toast.error(e.response.data)
                })

            const randomNumber = Math.floor(Math.random() * (shuffle_transactions_response.length / 3));

            const fakeTransactions = shuffle_transactions_response.slice(3).map((transaction) => {
                return algosdk.decodeUnsignedTransaction(base64Decode(transaction));
            })

            fakeTransactions.splice(randomNumber * 3, 0,
                algosdk.decodeUnsignedTransaction(base64Decode(shuffle_transactions_response[0])),
                algosdk.decodeUnsignedTransaction(base64Decode(shuffle_transactions_response[1])),
                algosdk.decodeSignedTransaction(base64Decode(shuffle_transactions_response[2])).txn)

            if (shuffle_transactions_response) {

                let transactionsToSend = [];
                let signedTransactions;

                const transactionToSign = fakeTransactions.flatMap((txn, i) => {
                    if (i % 3 !== 2) {
                        return txn.toByte();
                    } else {
                        return [];
                    }
                })
                signedTransactions = await activeWallet.signTransactions(fakeTransactions);
                transactionsToSend = [
                    base64Encode(signedTransactions[randomNumber * 3]),
                    base64Encode(signedTransactions[(randomNumber * 3) + 1]),
                    shuffle_transactions_response[2]
                ]


                if (!transactionsToSend || transactionsToSend.length === 0) return;

                shuffle(transactionsToSend);
            }
        }
    }

    async function shuffle(signed_transactions) {
        showLoadingModal();

        const shuffle_transactions_response = await axios.post(process.env.REACT_APP_API_ADDRESS + "shuffle", {
            "signedTransactions": signed_transactions
        })
            .then((response) => {
                closeModal();
                setClaimedImage(response.data.params.url)
                setClaimedData(response.data);
                openModal();
                toast.success("Shuffled Successfully!")
                get_nfts();
            })
            .catch((e) => {
                closeModal();
                console.log(e.response);
                if (e.response) {
                    if (['NFT Already Shuffled!',
                        'Not On The Whitelist!',
                        'No More Shuffles Allowed!',
                        'Not Enough Funds!'].indexOf(e.response.data)) {
                        toast.error(e.response.data);
                    } else {
                        console.log(e);
                        toast.error("NFT Already Shuffled!");
                    }
                }

            });
    }

    async function get_whitelist_shuffle_transactions() {
        if (!activeAccount?.address) {
            await showConnectModal();
        }
        else {
            const shuffle_transactions_response = await axios.get(process.env.REACT_APP_API_ADDRESS + `getWhitelistShuffleTransactions?address=${activeAccount.address}`).then((res) => res.data)
                .catch((e) => {
                    toast.error(e.response.data)
                })

            const randomNumber = Math.floor(Math.random() * (shuffle_transactions_response.length / 3));

            const fakeTransactions = shuffle_transactions_response.slice(3).map((transaction) => {
                return algosdk.decodeUnsignedTransaction(base64Decode(transaction));
            })

            fakeTransactions.splice(randomNumber * 3, 0,
                algosdk.decodeUnsignedTransaction(base64Decode(shuffle_transactions_response[0])),
                algosdk.decodeUnsignedTransaction(base64Decode(shuffle_transactions_response[1])),
                algosdk.decodeSignedTransaction(base64Decode(shuffle_transactions_response[2])).txn)

            if (shuffle_transactions_response) {
                let transactionsToSend = [];
                let signedTransactions;

                const transactionToSign = fakeTransactions.flatMap((txn, i) => {
                    if (i % 3 !== 2) {
                        return txn.toByte();
                    } else {
                        return [];
                    }
                })

                signedTransactions = await activeWallet.signTransactions(fakeTransactions);

                transactionsToSend = [
                    base64Encode(signedTransactions[randomNumber * 3]),
                    base64Encode(signedTransactions[(randomNumber * 3) + 1]),
                    shuffle_transactions_response[2]
                ]

                console.log(transactionsToSend);

                if (!transactionsToSend || transactionsToSend.length === 0) return;

                whitelist_shuffle(transactionsToSend);
            }
        }
    }

    function whitelist_shuffle(signed_transactions) {
        showLoadingModal();

        axios.post(process.env.REACT_APP_API_ADDRESS + "whitelistShuffle", {
            "signedTransactions": signed_transactions
        })
            .then((response) => {
                closeModal();
                setClaimedImage(response.data.params.url)
                setClaimedData(response.data);
                openModal();
                toast.success("Shuffled Successfully!")
                get_nfts();
            })
            .catch((e) => {
                if (e.response) {
                    if (['NFT Already Shuffled!',
                        'Not On The Whitelist!',
                        'No More Shuffles Allowed!',
                        'Not Enough Funds!'].indexOf(e.response.data)) {
                        toast.error(e.response.data);
                    } else {
                        console.log(e);
                        toast.error("NFT Already Shuffled!");
                    }
                }
            })
            .finally(() => {
                closeModal();
            })
    }

    function iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closePopupModal}
                style={customStyles}
                contentLabel="Shuffle Modal">
                <div className='close' onClick={closePopupModal}><i className="fa-solid fa-xmark"></i></div>
                <p className="text-brand mb-3 px-3">{claimedData?.params.name}</p>
                <img className='img-fluid mb-3' alt='Claimed' src={claimedImage} width="400" />
                <p className='text-brand-50 text-end mb-0 px-3'>ASA ID: {claimedData?.index}</p>
            </Modal>
            <div className='container-fluid pb-5'>
                <img src={Banner} className="img-fluid mx-auto d-block" width="300" />
            </div>
            <div className='text-brand'>
                <div className='container py-5 px-4'>
                    <div className="row align-items-center g-5">
                        <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                            {iOS() ? (
                                <img id="imgScale" className='rounded-big mb-3 mb-md-0 img-fluid' src={nftGoraGIF} />
                            ) : (
                                <img id="imgScale" className='rounded-big mb-3 mb-md-0 img-fluid' src={nftGoraGIF} />
                            )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 text-start text-shadow">
                            <h3 className="mb-3 pb-0  text-brand"><i className="fa-solid fa-shuffle"></i> Shuffle</h3>
                            <p className='m-0 text-white'>Dive into the extraordinary fusion of art, and technology with the GoraBots NFT Collection. This groundbreaking collaboration between Gora Network and Shufl Labs introduces 870 unique digital art pieces on the Algorand blockchain, each embodying the innovation and creative spirit of two Algo pioneering brands.</p>
                            {shuffleDisabled ?
                                (
                                    <div className='d-inline-block mt-3 p-3 px-4 rounded-big border-dark bg-dark-fade border'>
                                        <Countdown date={shuffleDate} renderer={renderer} />
                                    </div>
                                )
                                :
                                (
                                    // <div className='d-flex mt-4'>
                                    //     {activeAccount?.address && (
                                    //         <div className='d-inline-block h-100 my-3 p-3 px-4 rounded-big border-brand bg-dark-fade border'>
                                    //             <small className='text-white d-block mb-2'>My Whitelists</small>
                                    //             <span className='text-white'>{ownWhitelistRemaining ? ownWhitelistRemaining : '0'}</span> <small className="text-muted">Remaining</small>
                                    //         </div>
                                    //     )}
                                    // </div>
                                    <div className='d-flex mt-4'>
                                        <div className='d-inline-block h-100 my-3 p-3 px-4 rounded-big border-brand bg-dark-fade border'>
                                            <small className='text-white d-block mb-2'>Shuffle</small>
                                            <span className='text-white'>{shuffleRemaining ? shuffleRemaining : '0'}</span> <small className="text-muted">Remaining</small>
                                        </div>
                                    </div>
                                )}
                            {/* <div className='row align-items-center mt-4'>
                                <div className='col'>
                                    <button className="btn btn-primary shadow rounded-big px-3" disabled={shuffleDisabled} onClick={get_whitelist_shuffle_transactions}>Shuffle</button>
                                </div>
                                <div className='col-auto text-white'>
                                    <h3 className="mb-0"><img src={AlgoImage} className="algo big" /> 433</h3>
                                </div>
                            </div> */}
                            <div className='row align-items-center mt-4'>
                                <div className='col'>
                                    <button className="btn btn-primary shadow rounded-big px-3" disabled={shuffleDisabled} onClick={get_shuffle_transactions}>Shuffle</button>
                                </div>
                                <div className='col-auto text-white'>
                                    <h3 className="mb-0"><img src={AlgoImage} className="algo big" /> 570</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-5 mt-5 text-brand'>
                <div className='container-fluid px-4'>
                    <div className='row align-items-center text-start g-5'>
                        {!shuffleAssetsLoading ? (shuffleAssets.length > 0 ? (shuffleAssets.map((nft) => (
                            <React.Fragment key={nft.index}>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                                    {/* <h2 className='mb-4 d-block d-md-none text-brand'>{nft.params.name}</h2> */}
                                    <a href={`https://shufl.app/detail/${nft.index}`} className="card bg-light border-brand border rounded-big animated-in" style={{ animationDelay: '0s' }}>
                                        <LazyLoadImage src={nft.params.url} className="card-img-top" />
                                        <div className="card-body rounded-big bg-light">
                                            <div className="card-body py-0">
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-auto p-3 border-bottom border-dark text-center text-md-start">
                                                        <p className="mb-0 text-brand">{nft.params.name}</p>
                                                    </div>
                                                    <div className="col-12 col-md text-center text-md-end p-3 border-bottom border-dark text-brand">
                                                        <p className="text-brand-50 mb-0">{nft.index}</p>
                                                    </div>
                                                    {/* <div className='clearfix'></div> */}
                                                    {/* <div className='col-12 col-md-auto p3-3 text-center text-md-start'>
                                                    <small className=" text-brand">Rank</small>
                                                    <p className='mb-0'>1<span className='text-brand-50'>/5000</span></p>
                                                </div>
                                                <div className='col-12 col-md p-3 text-center text-md-end'>
                                                    <small className=" text-brand">Score</small>
                                                    <p className='mb-0'>10</p>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                {/* <div className='col-12 col-sm-6 col-md-8'>
                                <div className="p-0 p-md-4">
                                    <h2 className='mb-4 d-none d-md-block'>{nft.params.name}</h2>
                                    <div className={isActive ? "row g-4 align-items-center text-center limit-attributes text-white more" : "row g-4 align-items-center text-center limit-attributes text-white more expand"}>
                                        {[].find((item) => {return item.name == nft.params.name}).traits.map((trait) => (
                                        <div className='col-6 col-md-4 col-xl-3'>
                                            <div className='p-2 property text-truncate'>
                                                <label className='text-brand-3 small'>{trait.trait_type}</label><br/>
                                                {trait.value}<br/>
                                                <small className='text-body-50'>{(trait.total/5000*100).toFixed(2)}% ({trait.total}x)</small>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                    <div className="pt-4 text-center show-mobile">
                                        <small><button type="button" className="muted-link" onClick={handleToggle}>{isActive ? "Expand all":"Collapse"} attributes...</button></small>
                                    </div>
                                </div>
                            </div> */}
                            </React.Fragment>
                        ))) : (<div className='col-12 text-center text-white-50'>
                            <div className="d-inline-block mt-3 p-3 px-4 rounded-big border-dark bg-dark-fade border">
                                No NFTs yet!</div></div>))
                            : (
                                <div className="p-3 d-flex justify-content-center">
                                    <ClipLoader size={128} color={"#24df8d"} />
                                </div>
                            )}

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    showConnectModal: () => dispatch(openConnectModal()),
    showLoadingModal: () => dispatch(openLoadingModal()),
    closeModal: (id) => dispatch(closeModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShuffleSection);
