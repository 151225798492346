import {SHOW_CONNECT_MODAL, SHOW_WALLET_CONNECT_MODAL, SHOW_LOADING_MODAL, HIDE_MODAL} from '../constants/ActionTypes'

export const openConnectModal = () => {
  return async (dispatch) => {
    dispatch({
      type: SHOW_CONNECT_MODAL
    })
  }
}

export const openWalletConnectModal = () => {
  return async (dispatch) => {
    dispatch({
      type: SHOW_WALLET_CONNECT_MODAL
    })
  }
}

export const openLoadingModal = () => {
  return async (dispatch) => {
    dispatch({
      type: SHOW_LOADING_MODAL
    })
  }
}

export const closeModal = () => ({ type: HIDE_MODAL });