import React, { useRef, useCallback, useEffect } from 'react';
import {connect} from 'react-redux';

import {SHOW_CONNECT_MODAL} from 'Root/services/constants/ActionTypes';

import {closeModal} from 'Root/services/actions/actions';

import { Background, ModalWrapper, CloseModalButton, ConnectButtonWrapper, ConnectButton, ButtonLogo, ModalHeader } from './ConnectModalElements';

import MyAlgoLogo from 'Root/images/myalgo-wallet.png';

import { shortenAddress } from 'Root/helpers/shortenAddress';

import { useWallet } from '@txnlab/use-wallet-react'

const ConnectModal = ({connect_modal, closeModal}) => {
    const { wallets, activeWallet, activeAccount } = useWallet()

    function copyAddress(address) {
        navigator.clipboard.writeText(address);
        document.querySelector(".connect-button-wrapper p").textContent = "Copied";
        setTimeout(function() {
            if (document.querySelector(".connect-button-wrapper p")) {
                document.querySelector(".connect-button-wrapper p").textContent = shortenAddress(address, 10);
            }
        }, 1000)
    }

    const modalRef = useRef()

    const closeModalBackground = e => {
        if (modalRef.current === e.target) {
            closeModal()
        }
    }

    const keyPress = useCallback(e => {
        if (e.key === 'Escape') {
            closeModal()
        }
    },[closeModal])

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    })

    if (!connect_modal) {
        return null;
    }

    return (       
        <>
        { connect_modal.type === SHOW_CONNECT_MODAL &&
            <Background ref={modalRef} onClick={closeModalBackground}>
                <ModalWrapper>
                    <CloseModalButton onClick={closeModal}/>
                    <ModalHeader>
                    {!activeWallet
                      ? "Connect Your Wallet"
                      : "Wallet Connected"
                    }
                    </ModalHeader>
                    <ConnectButtonWrapper className="row">
                        {!activeWallet
                        ? <>
                            {wallets.map((wallet) => (
                                <div className='col-6 col-sm-6'>
                                    <ConnectButton onClick={() => {wallet.connect(); closeModal(); }}>
                                        <ButtonLogo src={`/images/${wallet.metadata.name}.png`} className="img-fluid"/>
                                        <span className="sr-only">{wallet.metadata.name}</span>
                                    </ConnectButton>
                                </div>
                            ))}
                            </>
                        : <>
                            <div className='col-12 text-center'>
                            <p onClick={() => {copyAddress(activeAccount.address)}}>{shortenAddress(activeAccount.address)}</p>
                            <ConnectButton className="btn btn-primary" onClick={activeWallet.disconnect}>
                                Disconnect
                            </ConnectButton>
                            </div>
                        </>
                    }
                    
                    </ConnectButtonWrapper>
                </ModalWrapper>
            </Background>
        }
            
        </>
    )
}

const mapStateToProps = (state) => ({
    connect_modal: state.connect_modal,
});
  
const mapDispatchToProps = (dispatch) => ({
    closeModal: (id) => dispatch(closeModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectModal);
