import React from 'react'
import { connect } from 'react-redux';

import toast from 'react-hot-toast';

import { openConnectModal } from "Root/services/actions/actions";

import { shortenAddress } from 'Root/helpers/shortenAddress';

import { useWallet } from '@txnlab/use-wallet-react'

function Navbar({ showConnectModal }) {
    const { wallets, activeWallet, activeAccount } = useWallet()

    function copyAddress(address) {
        navigator.clipboard.writeText(address);
        toast.success("Address Copied!")
    }

    function closeNav() {
        const el = document.querySelector('#navbarSupportedContent');
        el.classList.remove("show");
    }
    return (
        <div className="header position-absolute top-0">
            <nav className="navbar navbar-expand">
                <div className="container-fluid navBarHeader">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent">
                        <i className="fa-solid fa-bars text-body"></i>
                    </button>
                    <div className="collapse navbar-collapse pt-0 bg-none" id="navbarSupportedContent">
                        <div className="d-flex ms-auto me-0 text-end align-items-center">

                        </div>
                        <form className="d-block d-lg-flex pt-4 pb-5 pt-lg-0 pb-lg-0 text-center text-lg-end align-items-center">
                            {activeAccount?.address ? <div className="dropdown d-inline-block">
                                <button className="btn btn-primary btn-sm px-3 dropdown-toggle" type="button" id="walletDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-solid fa-wallet"></i><span className="d-inline d-lg-none d-xl-inline"> {activeAccount.address ? '' : "Connect"}</span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end text-center py-3 rounded-big" aria-labelledby="walletDropdown">
                                    <li><a onClick={() => { copyAddress(activeAccount.address) }} className="dropdown-item">{shortenAddress(activeAccount.address)}</a></li>
                                    <li><a className="dropdown-item" onClick={activeWallet.disconnect}>Disconnect</a></li>
                                </ul>
                            </div> : <a onClick={() => { showConnectModal(); closeNav(); }} className="btn btn-primary btn-sm px-3">Connect</a>}
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    )
}


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
    showConnectModal: () => dispatch(openConnectModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
