import React from 'react';
import { connect } from 'react-redux';

import { SHOW_LOADING_MODAL } from 'Root/services/constants/ActionTypes';

import { Background } from './LoadingModalElements';

import ClipLoader from "react-spinners/ClipLoader";

const LoadingModal = ({ loading_modal }) => {
    if (!loading_modal) {
        return null;
    }

    return (
        <>
            {loading_modal.type === SHOW_LOADING_MODAL &&
                <Background>
                    <ClipLoader size={200} color={"#24df8d"} />
                </Background>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    loading_modal: state.loading_modal,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingModal);
