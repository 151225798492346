import React, { useLayoutEffect } from 'react';
import {
  Routes,
  Route,
  useLocation } from 'react-router-dom';

import './App.scss';

import Shuffle from 'Pages/shuffle';
import ConnectModal from 'Components/Modals/ConnectModal/ConnectModal';
import WalletConnectModal from 'Components/Modals/WalletConnectModal/WalletConnectModal';
import LoadingModal from './components/Modals/LoadingModal/LoadingModal';

import { NetworkId, WalletId, WalletManager, WalletProvider } from '@txnlab/use-wallet-react'

const walletManager = new WalletManager({
  wallets: [
    WalletId.DEFLY,
    WalletId.PERA,
  ],
  network: NetworkId.MAINNET,
  algod: {
    token: '',
    baseServer: 'https://mainnet-api.algonode.cloud',
    port: '443'
  }
})

export default function App() {
  return (
    <WalletProvider manager={walletManager}>
      <Routes>
        <Route exact path='/' element={<Shuffle />} />
      </Routes>
      <ConnectModal />
      <WalletConnectModal />
      <LoadingModal />
    </WalletProvider>
  );
}
