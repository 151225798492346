import React from 'react'

import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

import ShuffleSection from '../components/Sections/ShuffleSection/Shuffle';

const Shuffle = () => {

    return (
        <>
            <Navbar />
            <div className="pb-5">
                <ShuffleSection />
            </div>
            <Footer />
        </>
    )
}

export default Shuffle
