import React from 'react'
import Banner from 'Root/images/logo.png';
import icon from 'Root/images/goraLogo.png';

const Footer = () => {
    return (
        <div className="footer pt-5 mt-5" id="footer">
            <div className="container-fluid pt-5 pb-2 text-center">
                <div className='row align-items-center mb-4 g-2'>
                    <div className='col'></div>
                    <div className='col-auto'>
                        <a href="https://x.com/GoraNetwork" target="_blank" rel="no-referrer noreferrer">
                            <i className="fa-brands fa-twitter fa-fw fa-2x"></i></a></div>
                    <div className='col-auto'>
                        <a href="https://www.gorabots.com/" target="_blank" rel="no-referrer noreferrer">
                            <img src={icon} width="54" alt='Gorabots' className='rounded-circle me-1' /></a></div>
                    <div className='col-auto'>
                        <a href="https://discord.com/invite/Pe8rwyPvKp" target="_blank" rel="no-referrer noreferrer">
                            <i className="fa-brands fa-discord fa-fw fa-2x"></i></a></div>
                    <div className='col'></div>
                </div>
                <img src={Banner} className="img-fluid mx-auto d-block mb-4" width="200" />
                <p className='m-0'>© All rights reserved</p>
            </div>
        </div>
    )
}

export default Footer
