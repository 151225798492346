import styled from 'styled-components';

export const Background = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0,0,0,0.6);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`